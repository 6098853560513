import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../context/UserContext';
import '../styles/Kitchen.css'; 
import { api } from '../utils/apiHelper';

const Kitchen = () => {
  const { authUser } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [expandedOrders, setExpandedOrders] = useState({});
  const [checkedItems, setCheckedItems] = useState(() => {
    const savedCheckedItems = localStorage.getItem('checkedItems');
    return savedCheckedItems ? JSON.parse(savedCheckedItems) : {};
  });
  const [updateCounts, setUpdateCounts] = useState(() => {
    const savedCounts = localStorage.getItem('updateCounts');
    return savedCounts ? JSON.parse(savedCounts) : {};
  });

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/orders`);
        if (response) {
          setOrders(response.orders);
          const initialExpanded = response.orders.reduce((acc, _, index) => {
            acc[index] = false; // Set all orders to collapsed initially
            return acc;
          }, {});
          setExpandedOrders(initialExpanded);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    if (authUser && authUser._id) {
      fetchOrders();
    }
  }, [authUser]);

  const toggleOrder = (index) => {
    setExpandedOrders(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleDeleteOrder = async (mainkey) => {
    if (window.confirm('Are you sure you want to delete this order?')) {
      try {
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/orders/${encodeURIComponent(mainkey)}`, 'DELETE');
        if (response) {
          setOrders(orders.filter(order => order.mainkey !== mainkey));
        }
      } catch (error) {
        console.error('Error deleting order:', error);
      }
    }
  };

  const handleCheckItem = (orderIndex, itemIndex) => {
    const updatedCheckedItems = { ...checkedItems };
    if (!updatedCheckedItems[orderIndex]) {
      updatedCheckedItems[orderIndex] = {};
    }

    const isChecked = updatedCheckedItems[orderIndex][itemIndex];
    if (isChecked) {
      if (window.confirm('Are you sure you want to unmark this item as completed?')) {
        updatedCheckedItems[orderIndex][itemIndex] = !isChecked;
      }
    } else {
      updatedCheckedItems[orderIndex][itemIndex] = !isChecked;
    }

    setCheckedItems(updatedCheckedItems);
    localStorage.setItem('checkedItems', JSON.stringify(updatedCheckedItems));
  };

  const isOrderComplete = (orderIndex) => {
    const orderCheckedItems = checkedItems[orderIndex] || {};
    return orders[orderIndex].items.every((_, itemIndex) => orderCheckedItems[itemIndex]);
  };

  // Full updateOrderStatus function with user existence check
  const updateOrderStatus = async (mainkey, status, PatronId) => {
    try {
      // Step 1: Check if the recipient user exists
      const userResponse = await api(`/users/${encodeURIComponent(PatronId)}`);
      
      if (!userResponse || !userResponse.user) {
        alert("The user you are trying to update no longer exists.");
        return;
      }

      // Step 2: Proceed with updating the order status
      const endpoint = status === 'Ready'
        ? `/users/${encodeURIComponent(PatronId)}/orders/${encodeURIComponent(mainkey)}/status/ready`
        : `/users/${encodeURIComponent(PatronId)}/orders/${encodeURIComponent(mainkey)}/status/ready-in-10-minutes`;

      const response = await api(endpoint, 'PUT');
      if (response) {
        const updatedOrders = orders.map(order =>
          order.mainkey === mainkey ? { ...order, status } : order
        );
        setOrders(updatedOrders);

        const newCounts = { ...updateCounts };
        if (!newCounts[mainkey]) {
          newCounts[mainkey] = { ready: 0, readyIn10: 0 };
        }
        if (status === 'Ready') {
          newCounts[mainkey].ready += 1;
        } else {
          newCounts[mainkey].readyIn10 += 1;
        }
        setUpdateCounts(newCounts);
        localStorage.setItem('updateCounts', JSON.stringify(newCounts));
      }
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  return (
    <div className="kitchen-container">
      <h2 className="kitchen-header">Kitchen Orders</h2>
      <div className="kitchen-container">
        <p className="kitchen-paragraph" style={{ color: 'black' }}>Total Orders: {orders.length}</p>
      </div>
      {orders.length > 0 ? (
        orders.map((order, index) => (
          <div key={order.mainkey} className="kitchen-order">
            <div onClick={() => toggleOrder(index)} className="kitchen-order-header" style={{ cursor: 'pointer' }}>
              <h3>Items:</h3>
              {order.items.map((item, itemIndex) => (
                <div key={itemIndex} className="kitchen-item">
                  <p className="kitchen-item-name">{item.itemName}</p>
                  <p>Item ID: {item.foodId}</p>
                  <p>Quantity: {item.quantity}</p>
                  <p>Notes: {item.notes || 'N/A'}</p>
                  <p>Price: ${item.price}</p>
                  <label>
                    <input
                      type="checkbox"
                      checked={checkedItems[index] && checkedItems[index][itemIndex]}
                      onChange={() => handleCheckItem(index, itemIndex)}
                    />
                      Mark as completed
                  </label>
                </div>
              ))}
            </div>
            {expandedOrders[index] && (
              <div>
                <p>Order Name: {order.ccname}</p>
                <p>Main Key: {order.mainkey}</p>
                <p>Timestamp: {order.timestamp}</p>
                <p>Items Count: {order.items.reduce((count, item) => count + Number(item.quantity), 0)}</p>
                <p>Cart Total: ${order.cartTotal.toFixed(2)}</p>
                <div className="kitchen-buttons">
                  <button onClick={() => updateOrderStatus(order.mainkey, 'Ready', order.PatronId)} className="kitchen-button ready">
                    Ready {updateCounts[order.mainkey]?.ready || 0}
                  </button>
                  <button onClick={() => updateOrderStatus(order.mainkey, 'Ready in 10 minutes', order.PatronId)} className="kitchen-button ready-in-10">
                    Ready in 10min! {updateCounts[order.mainkey]?.readyIn10 || 0}
                  </button>
                  <button onClick={() => handleDeleteOrder(order.mainkey)} className="kitchen-button delete">Delete Order</button>
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <p className="kitchen-paragraph">No orders found</p>
      )}
    </div>
  );
};

export default Kitchen;
