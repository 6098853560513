import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from "../context/UserContext";
import Kitchen from './Kitchen';
import PatronOrders from './PatronOrders';
import KitchenCompliments from './KitchenCompliments';
import PatronEvents from './PatronEvents';
import ThemeContext from '../context/ThemeContext';
import { api } from '../utils/apiHelper';

const Authenticated = () => {
  const { authUser } = useContext(UserContext);
  const { accentColor } = useContext(ThemeContext);
  const [patronOrders, setPatronOrders] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchPatronOrders = async () => {
      try {
        console.log('Fetching patron orders for user:', authUser._id);
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/patronOrders`);
        console.log('Fetched patron orders response:', response);
        setPatronOrders(response.patronOrders || []);
        console.log('Set patron orders:', response.patronOrders);
      } catch (error) {
        console.error('Error fetching patron orders:', error);
      }
    };

    const fetchOrders = async () => {
      try {
        console.log('Fetching orders for user:', authUser._id);
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/orders`);
        console.log('Fetched orders response:', response);
        setOrders(response.orders || []);
        console.log('Set orders:', response.orders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    if (authUser && authUser._id) {
      fetchPatronOrders();
      fetchOrders();
    } else {
      console.error('authUser or authUser._id is undefined');
    }
  }, [authUser]);

  return (
    <div style={{ padding: '20px', maxWidth: '500px', margin: '0 auto', textAlign: 'center' }}>
      <h1 style={{ fontFamily: 'Inter Tight, sans-serif' }}>{authUser && authUser.name} is Authenticated</h1>
      <p style={{ fontFamily: 'Cormorant Garamond, serif', fontWeight: '400', fontSize: '2em', marginBottom: '2px' }}>{authUser && authUser.username}</p>
      <KitchenCompliments />
      <div>
        {orders && orders.length > 0 ? (
          <Kitchen orders={orders} />
        ) : (
          <div style={{ border: `1px solid ${accentColor}`, borderRadius: '8px', padding: '20px', margin: '20px 0', textAlign: 'center', backgroundColor: '#f9f9f9' }}>
            <h2>Are You Ready to Create Your Store?</h2>
            <Link to="/createstore" style={{ color: '#007BFF', textDecoration: 'none', fontWeight: 'bold' }}>Create Store</Link>
          </div>
        )}
        {patronOrders && patronOrders.length > 0 ? (
          <PatronOrders patronOrders={patronOrders} />
        ) : (
          <div style={{ border: `2px solid ${accentColor}`, borderRadius: '8px', padding: '20px', margin: '20px 0', textAlign: 'center', backgroundColor: '#f9f9f9' }}>
            <h2>Want Something to Eat?</h2>
            <Link to="/storelist" style={{ color: '#007BFF', textDecoration: 'none', fontWeight: 'bold' }}>Browse Stores</Link>
          </div>
        )}
      </div>
      <PatronEvents />
    </div>
  );
};

export default Authenticated;
