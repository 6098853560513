import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from "../context/UserContext";
import PatronOrders from './PatronOrders';
import ThemeContext from '../context/ThemeContext';
import { api } from '../utils/apiHelper';
//trip

const Guest_Auth = () => {
  const { authUser } = useContext(UserContext);
  const { accentColor } = useContext(ThemeContext);
  const [patronOrders, setPatronOrders] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchPatronOrders = async () => {
      try {
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/patronOrders`);
        setPatronOrders(response.patronOrders);
      } catch (error) {
        console.error('Error fetching patron orders:', error);
      }
    };

    const fetchOrders = async () => {
      try {
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/orders`);
        setOrders(response.orders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    if (authUser) {
      fetchPatronOrders();
      fetchOrders();
    }
  }, [authUser]);

  return (
    <div style={{ padding: '20px', maxWidth: '500px', margin: '0 auto', textAlign: 'center' }}>
      <h1 style={{fontFamily: 'Inter Tight, sans-serif'}}>{authUser.name} is Authenticated</h1>
      <p style={{ fontFamily: 'Cormorant Garamond, serif', fontWeight: '400', fontSize: '2em', marginBottom: '2px'}}>{authUser.username}</p>
        {patronOrders.length > 0 ? (
          <PatronOrders patronOrders={patronOrders} />
        ) : (
          <div style={{ border: `2px solid ${accentColor}`, borderRadius: '8px', padding: '20px', margin: '20px 0', textAlign: 'center', backgroundColor: '#f9f9f9' }}>
            <h2>Want Something to Eat?</h2>
            <Link to="/storelist" style={{ color: '#007BFF', textDecoration: 'none', fontWeight: 'bold' }}>Browse Stores</Link>
          </div>
        )}
      </div>
  );
}

export default Guest_Auth;
