import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext';
import { api } from '../utils/apiHelper';
import '../styles/EventSale.css';

const EventSale = () => {
  const { authUser } = useContext(UserContext);
  const navigate = useNavigate();

  const generateUniqueKey = () => {
    return `seating_${Math.random().toString(36).substr(2, 9)}`;
  };

  const [event, setEvent] = useState({
    title: '',
    description: '',
    price: '',
    dates: [
      {
        startDateTime: '',
        seatings: [{ startTime: '', maxTickets: 1, key: generateUniqueKey() }],
      },
    ],
    location: {
      streetAddress: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },
    refundPolicy: 'no refund',
    storeId: authUser.store?.storeId || '', // Add storeId to the event
  });

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEvent((prevEvent) => ({ ...prevEvent, [name]: value }));
  };

  const handleDateChange = (index, e) => {
    const { name, value } = e.target;
    const updatedDates = event.dates.map((date, i) =>
      i === index ? { ...date, [name]: value } : date
    );
    setEvent((prevEvent) => ({ ...prevEvent, dates: updatedDates }));
  };

  const handleSeatingChange = (dateIndex, seatingIndex, e) => {
    const { name, value } = e.target;
    const updatedDates = event.dates.map((date, i) =>
      i === dateIndex
        ? {
            ...date,
            seatings: date.seatings.map((seating, j) =>
              j === seatingIndex ? { ...seating, [name]: value } : seating
            ),
          }
        : date
    );
    setEvent((prevEvent) => ({ ...prevEvent, dates: updatedDates }));
  };

  const addAnotherDate = () => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      dates: [...prevEvent.dates, { startDateTime: '', seatings: [{ startTime: '', maxTickets: 1, key: generateUniqueKey() }] }],
    }));
  };

  const addAnotherSeating = (dateIndex) => {
    const updatedDates = event.dates.map((date, i) =>
      i === dateIndex
        ? { 
            ...date, 
            seatings: [...date.seatings, { startTime: '', maxTickets: 1, key: generateUniqueKey() }] 
          }
        : date
    );
    setEvent((prevEvent) => ({ ...prevEvent, dates: updatedDates }));
  };

  const handleUseStoreLocation = () => {
    if (authUser.store && authUser.store.location) {
      setEvent((prevEvent) => ({
        ...prevEvent,
        location: {
          streetAddress: authUser.store.location.streetAddress || '',
          city: authUser.store.location.city || '',
          state: authUser.store.location.state || '',
          zipCode: authUser.store.location.zipCode || '',
          country: authUser.store.location.country || '',
        },
      }));
    }
  };

  const handleSummary = () => {
    navigate("/esummary");
  };

  const handleSubmit = async () => {
    try {
      const url = `/users/events/${encodeURIComponent(authUser._id)}`;
      const response = await api(url, 'POST', {
        ...event,
        userId: authUser._id,
        storeId: authUser.store?.storeId, // Include storeId in the event
      });
      setMessage(response.message || 'Event saved successfully!');
  
      // Clear the input fields after a successful post
      setEvent({
        title: '',
        description: '',
        price: '',
        dates: [
          {
            startDateTime: '',
            seatings: [{ startTime: '', maxTickets: 1, key: generateUniqueKey() }],
          },
        ],
        location: {
          streetAddress: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
        },
        refundPolicy: 'no refund',
        storeId: authUser.store?.storeId || '', // Reset storeId
      });
    } catch (error) {
      console.error('Error saving event:', error);
      setMessage('Error saving event. Please try again.');
    }
  };
  
  if (loading) {
    return <div>Loading event details...</div>;
  }

  return (
    <div className="card">
      <p1 onClick={handleSummary}>@event summary</p1>
      <h2 className="cardTitle">Event Details</h2>
      
      <div className="inputGroup">
        <label htmlFor="title">Event Title:</label>
        <input
          type="text"
          id="title"
          name="title"
          value={event.title}
          onChange={handleInputChange}
          className="input"
        />
      </div>

      <div className="inputGroup">
        <label htmlFor="description">Event Description:</label>
        <textarea
          id="description"
          name="description"
          value={event.description}
          onChange={handleInputChange}
          className="input"
        />
      </div>

      <div className="inputGroup">
        <label htmlFor="price">Event Price:</label>
        <input
          type="number"
          id="price"
          name="price"
          value={event.price}
          onChange={handleInputChange}
          className="input"
          min="0"
          step="0.01"
        />
      </div>

      {event.dates.map((date, dateIndex) => (
        <div key={dateIndex} className="dateSection">
          <div className="inputGroup">
            <label htmlFor="startDateTime">Start Date and Time:</label>
            <input
              type="datetime-local"
              name="startDateTime"
              value={date.startDateTime}
              onChange={(e) => handleDateChange(dateIndex, e)}
              className="input"
            />
          </div>

          {date.seatings.map((seating, seatingIndex) => (
            <div key={seating.key} className="seatingSection">
              <div className="inputGroup">
                <label htmlFor="startTime">Seating Start Time:</label>
                <input
                  type="time"
                  name="startTime"
                  value={seating.startTime}
                  onChange={(e) => handleSeatingChange(dateIndex, seatingIndex, e)}
                  className="input"
                />
              </div>

              <div className="inputGroup">
                <label htmlFor="maxTickets">Maximum Tickets Available:</label>
                <input
                  type="number"
                  name="maxTickets"
                  value={seating.maxTickets}
                  onChange={(e) => handleSeatingChange(dateIndex, seatingIndex, e)}
                  className="input"
                  min="1"
                />
              </div>
            </div>
          ))}

          <button onClick={() => addAnotherSeating(dateIndex)} className="button">
            Add Another Seating
          </button>
        </div>
      ))}

      <button onClick={addAnotherDate} className="button">
        Add Another Date
      </button>

      <div className="inputGroup">
        <label htmlFor="streetAddress">Street Address:</label>
        <input
          type="text"
          id="streetAddress"
          name="streetAddress"
          value={event.location.streetAddress}
          onChange={handleInputChange}
          className="input"
        />
      </div>
      <div className="inputGroup">
        <label htmlFor="city">City:</label>
        <input
          type="text"
          id="city"
          name="city"
          value={event.location.city}
          onChange={handleInputChange}
          className="input"
        />
      </div>
      <div className="inputGroup">
        <label htmlFor="state">State/Province:</label>
        <input
          type="text"
          id="state"
          name="state"
          value={event.location.state}
          onChange={handleInputChange}
          className="input"
        />
      </div>
      <div className="inputGroup">
        <label htmlFor="zipCode">ZIP/Postal Code:</label>
        <input
          type="text"
          id="zipCode"
          name="zipCode"
          value={event.location.zipCode}
          onChange={handleInputChange}
          className="input"
        />
      </div>
      <div className="inputGroup">
        <label htmlFor="country">Country:</label>
        <input
          type="text"
          id="country"
          name="country"
          value={event.location.country}
          onChange={handleInputChange}
          className="input"
        />
      </div>

      <button onClick={handleUseStoreLocation} className="button">
        Use Store Location
      </button>

      <div className="inputGroup">
        <label htmlFor="refundPolicy">Refund Policy:</label>
        <select
          id="refundPolicy"
          name="refundPolicy"
          value={event.refundPolicy}
          onChange={handleInputChange}
          className="input"
        >
          <option value="no refund">No Refund</option>
          <option value="custom">Custom</option>
        </select>
        {event.refundPolicy === 'custom' && (
          <textarea
            id="customRefundPolicy"
            name="customRefundPolicy"
            placeholder="Enter custom refund agreement"
            className="input"
            onChange={(e) => setEvent({ ...event, refundPolicy: e.target.value })}
          />
        )}
      </div>

      <button onClick={handleSubmit} className="button">
        Save Event
      </button>

      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default EventSale;
