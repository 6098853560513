import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ThemeContext from "../context/ThemeContext";
import Nav from "./Nav";
import "../styles/Header.css"; // Ensure this import is correct

const Header = () => {
  const { accentColor } = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".header")) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="header" style={{ background: accentColor }}>
      <div className="header-content">
        <Link to="/">
          <h1 className="header--logo roboto-black-italic">The Flying Pot</h1>
        </Link>
        <button className="menu-button" onClick={toggleMenu}>
          ☰
        </button>
        <div className="menu-list--desktop">
          <Nav menuOpen={true} />
        </div>
      </div>

      <div
        className={`menu-list--mobile ${menuOpen ? "open" : ""}`}
        style={{ background: accentColor }}
      >
        <Nav
          menuOpen={menuOpen}
          onClose={() => {
            setMenuOpen(false);
          }}
        />
      </div>
    </div>
  );
};

export default Header;
