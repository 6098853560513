import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../context/UserContext';
import useTheme from '../context/useTheme'; // Ensure this path is correct
import { api } from '../utils/apiHelper'; // Import your api function
import '../styles/createStore.css'; // Ensure this path is correct

const CreateStore = () => {
  const [storeName, setStoreName] = useState('');
  const [storeDescription, setStoreDescription] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [landmark, setLandmark] = useState('');
  const [items, setItems] = useState([{ _id: '', title: '', price: '', quantity: '', description: '', highlightColorIndex: 0 }]);
  const [storeExists, setStoreExists] = useState(false);
  const { authUser } = useContext(UserContext);
  const [highlightColorIndex, setHighlightColorIndex] = useState(1);

  const { accentColor } = useTheme();
  const highlightColors = ['white', 'lightblue', 'lightgreen'];

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/store`);
        
        if (response && response.name) {
          setStoreExists(true);
          setStoreName(response.name);
          setStoreDescription(response.description);
          const { streetAddress, city, state, zipCode, country, neighborhood, landmark } = response.location || {};
          setStreetAddress(streetAddress || '');
          setCity(city || '');
          setState(state || '');
          setZipCode(zipCode || '');
          setCountry(country || '');
          setNeighborhood(neighborhood || '');
          setLandmark(landmark || '');
        } else {
          setStoreExists(false);
        }
      } catch (error) {
        console.error('Error fetching store data:', error);
        setStoreExists(false);
      }
    };

    fetchStoreData();
  }, [authUser._id]);

  const generateUniqueItemId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < 7; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return randomString;
  };

  const handleStoreNameChange = (e) => {
    setStoreName(e.target.value);
  };

  const handleStoreDescriptionChange = (e) => {
    setStoreDescription(e.target.value);
  };

  const handleStreetAddressChange = (e) => {
    setStreetAddress(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleZipCodeChange = (e) => {
    setZipCode(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleNeighborhoodChange = (e) => {
    setNeighborhood(e.target.value);
  };

  const handleLandmarkChange = (e) => {
    setLandmark(e.target.value);
  };

  const handleItemChange = (index, e) => {
    const updatedItems = [...items];
    updatedItems[index][e.target.name] = e.target.value;
    setItems(updatedItems);
  };

  const addItem = () => {
    setItems(prevItems => [
      ...prevItems,
      { _id: generateUniqueItemId(), title: '', price: '', quantity: '', description: '', highlightColorIndex }
    ]);
    setHighlightColorIndex((prevIndex) => (prevIndex + 1) % highlightColors.length);
  };

  const removeItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const clearItemFields = () => {
    setItems([{ _id: '', title: '', price: '', quantity: '', description: '', highlightColorIndex: 0 }]);
  };

  const deleteStore = async () => {
    try {
      const response = await api(`/users/${encodeURIComponent(authUser._id)}/store`, 'DELETE');
      if (response) {
        console.log('Store deleted successfully');
        setStoreExists(false);
        setStoreName('');
        setStoreDescription('');
        setStreetAddress('');
        setCity('');
        setState('');
        setZipCode('');
        setCountry('');
        setNeighborhood('');
        setLandmark('');
        clearItemFields();
      }
    } catch (error) {
      console.error('Error deleting store:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = authUser._id;
    const requestURL = `/users/${encodeURIComponent(userId)}/store`;
  
    // Ensure all items have an _id before sending
    const itemsWithId = items.map(item => ({
      ...item,
      _id: item._id || generateUniqueItemId()
    }));
  
    // Validate that quantity and price are not negative
    const hasNegativeValues = itemsWithId.some(item => item.quantity < 0 || item.price < 0);
  
    if (hasNegativeValues) {
      alert('Quantity and price cannot have negative values. Please check your items.');
      return;
    }
  
    const location = {
      streetAddress,
      city,
      state,
      zipCode,
      country,
      neighborhood: neighborhood || '',
      landmark: landmark || ''
    };
  
    if (!storeExists) {
      // Validate that the store details are filled in
      if (!storeName || !storeDescription || !streetAddress || !city || !state || !zipCode || !country) {
        alert('Please fill out all required store details before adding items.');
        return;
      }
  
      const store = { name: storeName, description: storeDescription, location, items: itemsWithId };
      console.log('Store data being sent:', store);
  
      try {
        const response = await api(requestURL, 'POST', store);
        if (response) {
          console.log('Store added successfully:', response);
          setStoreExists(true);
          clearItemFields();
        }
      } catch (error) {
        console.error('Error adding store:', error);
      }
    } else {
      const newItem = itemsWithId[itemsWithId.length - 1];
      console.log('New item being sent:', newItem);
  
      try {
        const response = await api(`${requestURL}/items`, 'POST', newItem);
        if (response) {
          console.log('Item added successfully:', response);
          clearItemFields();
        }
      } catch (error) {
        console.error('Error adding item:', error);
      }
    }
  };
  

  return (
    <div className="card" style={{ borderColor: accentColor }}>
      {storeExists ? (
        <>
          <h1 style={{ fontWeight: 800, color: 'black' }}>{storeName}</h1>
          <p className="paragraph">{storeDescription}</p>
          <p className="paragraph">{`${streetAddress}, ${city}, ${state} ${zipCode}, ${country}`}</p>
          {neighborhood && <p className="paragraph">Neighborhood: {neighborhood}</p>}
          {landmark && <p className="paragraph">Landmark: {landmark}</p>}
          <div style={{ marginBottom: '20px' }}>
            <button type="button" onClick={deleteStore} className="button button-remove">Delete Store</button>
          </div>
          <div className="separator"></div>
        </>
      ) : (
        <h2 className="paragraph">Create Store</h2>
      )}
      <form onSubmit={handleSubmit}>
        {!storeExists && (
          <>
            <div className="margin-medium">
              <label>Store Name:</label>
              <input type="text" value={storeName} onChange={handleStoreNameChange} required />
            </div>
            <div className="margin-medium">
              <label>Store Description:</label>
              <input type="text" value={storeDescription} onChange={handleStoreDescriptionChange} required />
            </div>
            <div className="margin-medium">
              <label>Street Address:</label>
              <input type="text" value={streetAddress} onChange={handleStreetAddressChange} required />
            </div>
            <div className="margin-medium">
              <label>City:</label>
              <input type="text" value={city} onChange={handleCityChange} required />
            </div>
            <div className="margin-medium">
              <label>State/Province/Region:</label>
              <input type="text" value={state} onChange={handleStateChange} required />
            </div>
            <div className="margin-medium">
              <label>ZIP/Postal Code:</label>
              <input type="text" value={zipCode} onChange={handleZipCodeChange} required />
            </div>
            <div className="margin-medium">
              <label>Country:</label>
              <input type="text" value={country} onChange={handleCountryChange} required />
            </div>
            {/* Optional Fields */}
            <div className="margin-medium">
              <label>Neighborhood or District (optional):</label>
              <input type="text" value={neighborhood} onChange={handleNeighborhoodChange} />
            </div>
            <div className="margin-medium">
              <label>Landmark or Intersection (optional):</label>
              <input type="text" value={landmark} onChange={handleLandmarkChange} />
            </div>
          </>
        )}
        <h2 className="paragraph text-center">Items</h2>
        {items.map((item, index) => (
          <div key={item._id || index} style={{ backgroundColor: highlightColors[item.highlightColorIndex || 0], marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
            <div className="margin-medium">
              <label>Title:</label>
              <input type="text" name="title" value={item.title} onChange={(e) => handleItemChange(index, e)} required />
            </div>
            <div className="margin-medium">
              <label>Price:</label>
              <input type="number" name="price" value={item.price} onChange={(e) => handleItemChange(index, e)} min="0" required />
            </div>
            <div className="margin-medium">
              <label>Quantity:</label>
              <input type="number" name="quantity" value={item.quantity} onChange={(e) => handleItemChange(index, e)} min="0" required />
            </div>
            <div className="margin-medium">
              <label>Description:</label>
              <input type="text" name="description" value={item.description} onChange={(e) => handleItemChange(index, e)} required />
            </div>
            {items.length > 1 && (
              <button type="button" onClick={() => removeItem(index)} className="button button-remove">Remove Field</button>
            )}
          </div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <button type="button" onClick={addItem} className="button">New Field</button>
          <button type="submit" className="button">{storeExists ? 'Add Item' : 'Create Store'}</button>
        </div>
      </form>
    </div>
  );
};

export default CreateStore;
//heroku update

