import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../context/UserContext';
import { api } from '../utils/apiHelper';
import SoldEvents from './SoldEvents'; // Import SoldEvents component
import '../styles/EventSummary.css';

const EventSummary = () => {
  const { authUser } = useContext(UserContext);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await api(`/users/events/${authUser._id}`, 'GET');
        if (response.error) {
          throw new Error(response.error);
        }
        setEvents(response); // Assuming the response is the full array of events
      } catch (error) {
        console.error('Error fetching events:', error);
        setError('No events found or failed to load events.');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [authUser._id]);

  const handleDeleteEvent = async (eventId) => {
    try {
      const response = await api(`/users/events/${encodeURIComponent(authUser._id)}/${eventId}`, 'DELETE');
      
      if (response.error) {
        throw new Error(response.error);
      }

      setEvents(prevEvents => {
        const updatedEvents = prevEvents.filter(event => event._id !== eventId);
        console.log('Updated events state after delete:', updatedEvents);
        return updatedEvents;
      });
    } catch (error) {
      console.error('Error deleting event:', error);
      setError('Failed to delete the event. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading events...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="event-summary-container">
      {events.length === 0 ? (
        <div>No events available.</div>
      ) : (
        events.map((event) => (
          <div key={event._id} className="event-card">
            <h1>{event.title}</h1>
            <p>Description: {event.description}</p>
            <p className="price">Price: ${event.price}</p>
            <p>
              Location: {event.location?.streetAddress}, {event.location?.city}, {event.location?.state}, {event.location?.zipCode}, {event.location?.country}
            </p>
            <p>Dates:</p>
            {event.dates?.map((date, index) => (
              <div key={index} className="date-section">
                <strong>{new Date(date.startDateTime).toLocaleString()}</strong>
                {date.seatings?.map((seating) => (
                  <div key={seating.key} className="seating-section">
                    <p>Seating: {new Date(`1970-01-01T${seating.startTime}Z`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}, Max Tickets: {seating.maxTickets}</p>
                  </div>
                ))}
              </div>
            ))}
            <p>Refund Policy: {event.refundPolicy}</p>
            <button onClick={() => handleDeleteEvent(event._id)} className="button">
              Delete Event
            </button>
          </div>
        ))
      )}
      <SoldEvents /> {/* Integrate SoldEvents component here */}
    </div>
  );
};

export default EventSummary;
