import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/StoreList.css';
import { api } from '../utils/apiHelper';

        const StoresList = () => {
          const [stores, setStores] = useState([]);
          const navigate = useNavigate();

          useEffect(() => {
            const fetchStores = async () => {
              try {
                const response = await api('/stores');
                console.log('Fetched stores:', response.stores); // Log the fetched stores
                setStores(response.stores); // Ensure the stores are set correctly from the response data
              } catch (error) {
                console.error('Error fetching stores:', error);
              }
            };

            fetchStores();
          }, []);

                const formatAddress = (location) => {
                  if (!location) return 'No location available';
                  
                  const { streetAddress, city, state, zipCode, country, neighborhood, landmark } = location;
                  let formattedAddress = `${streetAddress}, ${city}, ${state} ${zipCode}, ${country}`;
                  
                  if (neighborhood) {
                    formattedAddress += `, Neighborhood: ${neighborhood}`;
                  }
                  
                  if (landmark) {
                    formattedAddress += `, Landmark: ${landmark}`;
                  }
                
                  return formattedAddress;
                };

              const handleStoreClick = (storeId) => {
                console.log('Navigating to store with ID:', storeId); // Log the storeId to ensure it's correct
                navigate(`/stores/${storeId}`);
              };

        return (
          <div className="stores-list-container">
            <h1 className="stores-list-header">All Stores</h1>
            {stores.length > 0 ? (
              stores.map((store) => (
                <div key={store._id} className="store-card" onClick={() => handleStoreClick(store.storeId)}>
                  <h2 className="store-title">{store.name}</h2>
                  <p className="store-description">Description: {store.description}</p>
                  <p className="store-location">Location: {formatAddress(store.location)}</p>
                  <p className="store-owner">Owner: {store.userName} ({store.userEmail})</p>
                </div>
              ))
            ) : (
              <p className="no-stores">No stores found</p>
            )}
          </div>
        );
      };

export default StoresList;
