import React from 'react';
import '../styles/Home.css'; // Ensure this path is correct
import BusinessPage from "../components/Business";


const Home = () => {
  return (
    <div className="container" style={{ backgroundColor: 'white', padding: '2px' }}>
          <div>
            <img src="/intro_image.png" alt="HotPot" className="image" />
          </div>
          <BusinessPage />
    </div>
  );
};

export default Home;