import React, { useEffect, useState, useContext } from 'react';
import UserContext from '../context/UserContext';
import { api } from '../utils/apiHelper';
import '../styles/PatronEvents.css'; // Import the CSS file

const PatronEvents = () => {
    const { authUser } = useContext(UserContext);
    const [events, setEvents] = useState([]);
    const [error, setError] = useState(null);
    const [collapsed, setCollapsed] = useState(true); // State to handle collapse/expand
  
    useEffect(() => {
      const fetchEvents = async () => {
        try {
          const response = await api(`/users/${encodeURIComponent(authUser._id)}/events`);
          if (response && Array.isArray(response)) {
            setEvents(response);
          } else {
            setError('Failed to fetch events.');
          }
        } catch (error) {
          console.error('Error fetching events:', error);
          setError('Failed to fetch events.');
        }
      };
  
      if (authUser && authUser._id) {
        fetchEvents();
      }
    }, [authUser]);
  
    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };
  
    const totalTickets = events.reduce((acc, event) => acc + (event.tickets?.length || 0), 0); // Calculate total tickets
  
    const formatTime = (timeString) => {
      const date = new Date(timeString);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const formattedHours = hours % 12 || 12; // Convert to 12-hour format
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const ampm = hours >= 12 ? 'PM' : 'AM';
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };
  
    return (
      <div>
        <h2>Ticket Box</h2>
        {error && <p className="error">{error}</p>}
        {events.length > 0 ? (
          <div>
            <button onClick={toggleCollapse} className="collapse-button">
              {collapsed ? `Show All Events (${events.length})` : 'Collapse'}
            </button>
            <p>Total Tickets: {totalTickets}</p>
            <ul>
              {events.map((event, index) => (
                (collapsed && index > 0) ? null : ( // Show only one event when collapsed
                  <li key={event._id} className="event-list-item">
                    <h3>{event.eventName}</h3>
                    <p>{event.description}</p>
                    <p>Date: {new Date(event.date).toLocaleString()}</p>
                    <p>Seating: {formatTime(event.date)}</p> {/* Format the seating time */}
                    <p>Quantity: {event.quantity}</p>
                    <p>Price: ${event.price.toFixed(2)}</p>
                    <h4>Tickets:</h4>
                    <ul className="ticket-list">
                      {event.tickets.map((ticket, ticketIndex) => (
                        <li key={ticketIndex} className="ticket-id">Ticket ID: {ticket.ticketId}</li>
                      ))}
                    </ul>
                  </li>
                )
              ))}
            </ul>
          </div>
        ) : (
          <p>No events found.</p>
        )}
      </div>
    );
  };
  
  export default PatronEvents;