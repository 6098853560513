import React, { useState, useEffect, useContext } from 'react';
import UserContext from "../context/UserContext";
import { api } from '../utils/apiHelper';
import '../styles/EventSelection.css';

const EventSelection = ({ storeId, cart, setCart, updateTotal }) => {
  const [events, setEvents] = useState([]);
  const { authUser } = useContext(UserContext);
  const [quantities, setQuantities] = useState({});
  const [showAllEvents, setShowAllEvents] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await api(`/stores/${storeId}/events`);
        setEvents(response);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    if (storeId) {
      fetchEvents();
    }
  }, [authUser._id, storeId]);

  const handleQuantityChange = (eventId, dateIndex, seatingKey, value) => {
    setQuantities(prev => ({
      ...prev,
      [`${eventId}_${dateIndex}_${seatingKey}`]: value,
    }));
  };

  const addToCart = async (event, dateIndex, seating) => {
    const key = `${event._id}_${dateIndex}_${seating.key}`;
    const quantity = quantities[key];

    if (!quantity || quantity <= 0) {
      console.error('Quantity must be greater than 0');
      return;
    }

    const cartItem = {
      _id: '_' + Math.random().toString(36).substr(2, 9), // Generate unique _id for cart item
      eventName: event.title,
      description: event.description,
      date: event.dates[dateIndex].startDateTime,
      seating: seating.startTime,
      quantity: parseInt(quantity),
      price: parseFloat(event.price), // Ensure price is a number
      eventId: event._id,
      seatingKey: seating.key,
      storeId: storeId, // Include the storeId in the cart item
    };

    try {
      const response = await api(`/users/${encodeURIComponent(authUser._id)}/cart`, 'POST', { item: cartItem });
      console.log('Item added to cart:', response);

      // Update the cart state
      const updatedCart = [...cart, cartItem];
      setCart(updatedCart);

      // Recalculate the total and update it in the parent component
      const totalAmount = updatedCart.reduce((acc, item) => acc + (item.price * item.quantity), 0);
      updateTotal(totalAmount);

      // Clear the quantity input field
      setQuantities(prev => ({
        ...prev,
        [key]: '', // Clear the input field
      }));
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };


  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format, handle 0 as 12
    return `${formattedHour}:${minutes} ${ampm}`; // Format to "5:00 PM"
  };

  const numberToWord = (index) => {
    const words = ["One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten"];
    return words[index] || (index + 1);
  };

  const toggleShowAllEvents = () => {
    setShowAllEvents(prev => !prev);
  };

  return (
    <div className="event-selection-container">
      {events.length > 0 && (
        <h1 className="event-toggle" onClick={toggleShowAllEvents}>
          Events at This Store 
          {showAllEvents ? ' (Collapse)' : ' (Show All)'}
        </h1>
      )}
      {events.length > 0 ? (
        (showAllEvents ? events : [events[0]]).map(event => (
          <div key={event._id} className="event-card">
            <h2 className="event-title">{event.title}</h2>
            <p>{event.description}</p>
            <p>Price: ${event.price}</p>
            {event.dates.map((date, dateIndex) => (
              <div key={`${event._id}_${dateIndex}`} className="event-date">
                <h3>{new Date(date.startDateTime).toLocaleDateString()}</h3>
                <h4 className="seatings-label">Showing</h4>
                {date.seatings.map((seating, seatingIndex) => (
                  <div key={seating.key} className="event-seating">
                    <p className="seating-number">Seating {numberToWord(seatingIndex)}</p>
                    <p>Start Time: {formatTime(seating.startTime)}</p>
                    <p>Max Tickets: {seating.maxTickets}</p>
                    <label>Quantity:</label>
                    <input
                      type="number"
                      min="1"
                      max={seating.maxTickets}
                      value={quantities[`${event._id}_${dateIndex}_${seating.key}`] || ''}
                      onChange={(e) =>
                        handleQuantityChange(event._id, dateIndex, seating.key, e.target.value)
                      }
                    />
                    <button onClick={() => addToCart(event, dateIndex, seating)}>
                      Add to Cart
                    </button>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))
      ) : (
        <p>No events available</p>
      )}
    </div>
  );
};

export default EventSelection;
