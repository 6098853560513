import React, { useEffect } from 'react';

const Cart = ({ cart = [], total = 0 }) => {
  useEffect(() => {
    console.log('Cart updated:', cart);
    console.log('Total updated:', total);
  }, [cart, total]);

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format, handle 0 as 12
    return `${formattedHour}:${minutes} ${ampm}`; // Format to "5:00 PM"
  };

  return (
    <div>
      <h2>Cart</h2>
      {cart.length > 0 ? (
        cart.map((cartItem, index) => (
          <div key={index}>
            {cartItem.foodId ? (
              // Render for food items
              <p>
                {cartItem.itemName} - Quantity: {cartItem.quantity} - Notes: {cartItem.notes || 'N/A'} - Price: ${parseFloat(cartItem.price).toFixed(2)} - Store: {cartItem.storeName}
              </p>
            ) : (
              // Render for events
              <p>
                {cartItem.eventName} - Quantity: {cartItem.quantity} - Date: {new Date(cartItem.date).toLocaleDateString()} - Seating: {formatTime(cartItem.seating)} - Price: ${parseFloat(cartItem.price).toFixed(2)}
              </p>
            )}
          </div>
        ))
      ) : (
        <p>Cart is empty</p>
      )}
      <h3>Total: ${parseFloat(total).toFixed(2)}</h3>
    </div>
  );
};

export default Cart;
