import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext';
import '../styles/UpdateStore.css';
import { api } from '../utils/apiHelper';

const UpdateStore = () => {
  const [storeName, setStoreName] = useState('');
  const [storeDescription, setStoreDescription] = useState('');
  const [storeLocation, setStoreLocation] = useState('');
  const [items, setItems] = useState([]);
  const [storeExists, setStoreExists] = useState(false);
  const [editItemIndex, setEditItemIndex] = useState(null);
  const [editItemData, setEditItemData] = useState({ title: '', description: '', price: '', quantity: '' });
  const [isEditingStore, setIsEditingStore] = useState(false);
  const [newItemData, setNewItemData] = useState({ title: '', description: '', price: '', quantity: '' });
  const [flashMessage, setFlashMessage] = useState('');
  const navigate = useNavigate();
  const { authUser } = useContext(UserContext);

  const [originalStoreData, setOriginalStoreData] = useState({});

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/store`);
        if (response.name) {
          setStoreExists(true);
          setStoreName(response.name);
          setStoreDescription(response.description);
          setStoreLocation(response.location);
          setItems(response.items || []);
          setOriginalStoreData({
            name: response.name,
            description: response.description,
            location: response.location
          });
        } else {
          setStoreExists(false);
        }
      } catch (error) {
        console.error('Error fetching store data:', error);
      }
    };

    fetchStoreData();
  }, [authUser._id]);

  const generateUniqueItemId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < 7; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return randomString;
  };

  const deleteStore = async () => {
    if (window.confirm('Are you sure you want to delete your store? This action cannot be undone.')) {
      try {
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/store`, 'DELETE');
        if (response.message === 'Store deleted successfully') {
          setStoreExists(false);
          setStoreName('');
          setStoreDescription('');
          setStoreLocation('');
          setItems([]);
          setFlashMessage('Store deleted successfully');
        }
      } catch (error) {
        console.error('Error deleting store:', error);
      }
    }
  };

  const startEditItem = (index) => {
    setEditItemIndex(index);
    setEditItemData(items[index]);
  };

  const handleEditItemChange = (e) => {
    const { name, value } = e.target;
    setEditItemData({ ...editItemData, [name]: value });
  };

  // Validation for editing items to prevent negative values
  const saveEditItem = async () => {
    if (editItemData.price < 0 || editItemData.quantity < 0) {
      setFlashMessage('Price and quantity cannot be negative.');
      return;
    }

    try {
      const updatedItems = items.map((item, index) => (index === editItemIndex ? editItemData : item));
      const itemId = editItemData._id;
      const response = await api(
        `/users/${encodeURIComponent(authUser._id)}/store/items/${encodeURIComponent(itemId)}`,
        'PUT',
        editItemData
      );

      if (response) {
        console.log('Item updated successfully');
        setItems(updatedItems);
        setEditItemIndex(null);
      }
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const removeItem = async (itemId, index) => {
    if (!itemId) {
      console.error('Invalid item ID');
      return;
    }
    try {
      const response = await api(`/users/${encodeURIComponent(authUser._id)}/store/items/${encodeURIComponent(itemId)}`, 'DELETE');

      if (response) {
        console.log('Item removed successfully');
        const updatedItems = items.filter((item, i) => i !== index);
        setItems(updatedItems);
      }
    } catch (error) {
      console.error('Error removing item:', error);
    }
  };

  const startEditStore = () => {
    setIsEditingStore(true);
    setFlashMessage('');
  };

  const cancelEditStore = () => {
    setStoreName(originalStoreData.name);
    setStoreDescription(originalStoreData.description);
    setStoreLocation(originalStoreData.location);
    setIsEditingStore(false);
    setFlashMessage('');
  };

  const saveEditStore = async () => {
    if (storeName === '' || storeDescription === '' || storeLocation === '') {
      setFlashMessage('Please fill in all fields before saving.');
      return;
    }
    try {
      const updatedStore = {
        name: storeName,
        description: storeDescription,
        location: storeLocation,
        items: items
      };
      const response = await api(`/users/${encodeURIComponent(authUser._id)}/store`, 'PUT', updatedStore);
      if (response) {
        setStoreExists(true);
        setIsEditingStore(false);
        setFlashMessage('');
        setOriginalStoreData({
          name: storeName,
          description: storeDescription,
          location: storeLocation,
        });
      } else {
        setFlashMessage('Failed to save store changes.');
      }
    } catch (error) {
      console.error('Cannot save unchanged fields. If no changes, please press cancel:', error);
      setFlashMessage('Cannot save unchanged fields. If no change, please press cancel');
    }
  };

  const handleNewItemChange = (e) => {
    const { name, value } = e.target;
    setNewItemData({ ...newItemData, [name]: value });
  };

  const compliments = (e) => {
    navigate('/compliments');
  };

  const events = (e) => {
    navigate('/events');
  };

  // Validation for new items to prevent negative values
  const addNewItem = async () => {
    if (
      newItemData.title === '' ||
      newItemData.description === '' ||
      newItemData.price === '' ||
      newItemData.quantity === ''
    ) {
      setFlashMessage('Please fill in all fields before adding a new item.');
      return;
    }

    if (newItemData.price < 0 || newItemData.quantity < 0) {
      setFlashMessage('Price and quantity cannot be negative.');
      return;
    }

    const newItemWithIdAndColor = {
      ...newItemData,
      _id: generateUniqueItemId(),
      highlightColorIndex: 0
    };

    try {
      const response = await api(`/users/${encodeURIComponent(authUser._id)}/store/items`, 'POST', newItemWithIdAndColor);

      if (response) {
        console.log('Item added successfully');
        const newItem = response;
        setItems([...items, newItem]);
        setNewItemData({ title: '', description: '', price: '', quantity: '' });
        setFlashMessage('');
      }
    } catch (error) {
      console.error('Error adding new item:', error);
    }
  };

  const formatLocation = (location) => {
    if (!location) return '';
    const { streetAddress, city, state, zipCode, country, neighborhood, landmark } = location;
    let formattedLocation = `${streetAddress}, ${city}, ${state} ${zipCode}, ${country}`;
    if (neighborhood) {
      formattedLocation += `, Neighborhood: ${neighborhood}`;
    }
    if (landmark) {
      formattedLocation += `, Landmark: ${landmark}`;
    }
    return formattedLocation;
  };

  return (
    <div className="update-store-container">
      {flashMessage && <div className="update-store-flash-message">{flashMessage}</div>}
      {storeExists ? (
        <>
          {isEditingStore ? (
            <>
              <div className="update-store-input-group">
                <label>Store Name:</label>
                <input type="text" value={storeName} onChange={(e) => setStoreName(e.target.value)} required />
              </div>
              <div className="update-store-input-group">
                <label>Store Description:</label>
                <input type="text" value={storeDescription} onChange={(e) => setStoreDescription(e.target.value)} required />
              </div>
              <div className="update-store-input-group">
                <label>Store Location:</label>
                <input
                  type="text"
                  value={formatLocation(storeLocation)}
                  onChange={(e) => setStoreLocation(e.target.value)} 
                  required
                />
              </div>
              <div className="update-store-button-group">
                <button onClick={saveEditStore} className="update-store-button">
                  <img src="save.png" alt="Save" className="icon" />
                </button>
                <button onClick={cancelEditStore} className="update-store-button update-store-button-cancel">
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              <h1 className="update-store-store-title-bona-nova">{storeName}</h1>
              <p className="update-store-store-description">{storeDescription}</p>
              <p className="update-store-store-location">{formatLocation(storeLocation)}</p>
              <div className="update-store-button-group">
                <button onClick={startEditStore} className="update-store-button">
                  <img src="edit.png" alt="Edit" className="icon" />
                </button>
                <button onClick={events} className="update-store-button update-store-button-ticket">
                  <img src="ticket.png" alt="Events" className="icon" />
                </button>
                <button onClick={compliments} style={{ color: "black" }} className="update-store-button update-store-button-ticket">
                  %
                </button>
                <button onClick={deleteStore} className="update-store-button update-store-button-remove">
                  Delete Store
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <h2 className="update-store-no-store">No Store Found</h2>
      )}
      <div>
        <h2 className="update-store-items-title">----</h2>
        {items.map((item, index) => (
          <div key={item._id} className="update-store-item-card">
            {editItemIndex === index ? (
              <>
                <div className="update-store-input-group">
                  <label>Title:</label>
                  <input type="text" name="title" value={editItemData.title} onChange={handleEditItemChange} required />
                </div>
                <div className="update-store-input-group">
                  <label>Price:</label>
                  <input type="number" name="price" value={editItemData.price} onChange={handleEditItemChange} min="0" required />
                </div>
                <div className="update-store-input-group">
                  <label>Quantity:</label>
                  <input type="number" name="quantity" value={editItemData.quantity} onChange={handleEditItemChange} min="0" required />
                </div>
                <div className="update-store-input-group">
                  <label>Description:</label>
                  <input type="text" name="description" value={editItemData.description} onChange={handleEditItemChange} required />
                </div>
                <div className="update-store-button-group">
                  <button onClick={saveEditItem} className="update-store-button">
                    <img src="save.png" alt="Save" className="icon" />
                  </button>
                  <button onClick={() => setEditItemIndex(null)} className="update-store-button update-store-button-cancel">
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <h1 className="update-store-item-title">{item.title}</h1>
                <p className="update-store-item-detail">Quantity: {item.quantity}</p>
                <p className="update-store-item-detail">Description: {item.description}</p>
                <p className="update-store-item-detail">Price: ${item.price}</p>
                <div className="update-store-button-group">
                  <button onClick={() => startEditItem(index)} className="update-store-button">
                    <img src="edit.png" alt="Edit" className="icon" />
                  </button>
                  <button onClick={() => removeItem(item._id, index)} className="update-store-button update-store-button-remove">
                    <img src="remove.png" alt="Remove" className="icon" />
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
        <div className="update-store-new-item-card">
          <h2 className="update-store-new-item-title">New Item</h2>
          <div className="update-store-input-group">
            <label>Title:</label>
            <input type="text" name="title" value={newItemData.title} onChange={handleNewItemChange} required />
          </div>
          <div className="update-store-input-group">
            <label>Price:</label>
            <input type="number" name="price" value={newItemData.price} onChange={handleNewItemChange} min="0" required />
          </div>
          <div className="update-store-input-group">
            <label>Quantity:</label>
            <input type="number" name="quantity" value={newItemData.quantity} onChange={handleNewItemChange} min="0" required />
          </div>
          <div className="update-store-input-group">
            <label>Description:</label>
            <input type="text" name="description" value={newItemData.description} onChange={handleNewItemChange} required />
          </div>
          <button onClick={addNewItem} className="update-store-button update-store-add-item-button">
            Add New Item
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateStore;
//heroku updates