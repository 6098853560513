import React from 'react';
import '../styles/AboutPage.css';

const AboutPage = () => {
  return (
    <div className="about-page">
      <div>
        <img src="/about.png" alt="HotPot" className="image" />
      </div>
      <h1>About The Flying Pot</h1>

      <section className="about-content">
        <div className="about-card">
          <p style={{ textIndent: '20px' }}>
            The <span className="highlight">Flying Pot</span> was founded by a passionate <span className="highlight">technologist and culinary</span> enthusiast, affectionately known as "Capitan."
          </p>
        </div>

        <div className="about-card">
          <p>
            Mr. Patterson is the son of a talented <span className="highlight">chef</span>. One of his earliest memories is riding with his father to deliver soup to a <span className="highlight">small shop</span> near his Georgia home—an occasion he thinks about often. Growing up, his first job was as a dishwasher in a country club kitchen run by his father. Over the years, he took on more roles in the kitchen, gaining valuable experiences from the people and work in that environment. His time spent in the front and back of the house, as well as holding positions in <span className="highlight">goods retailing and distribution</span>, ingrained in him a deep respect for the <span className="highlight">art and business</span> of the culinary world.
          </p>
        </div>

        <div className="about-card">
          <p>
            However, his father’s passion is <span className="highlight">cooking and the kitchen</span>. As a young man, Mr. Patterson's interests were somewhere else. He loved <span className="highlight">playing *Bomberman* and daydreaming.</span> As an older man, he loves <span className="highlight">software programming and technical production</span>... and still daydreaming. Consistent waves of thoughts about things like “Which productions got the most out of their efforts?” or “What new skill can be learned in <span className="highlight">software</span>?”
          </p>
        </div>

        <div className="about-card">
          <p>
            These interests moved him to create<span className="highlight"> The Flying Pot, a technology and media production company</span>. The flagship product of The Flying Pot is a thoughtfully designed online takeout order management system created to support <span className="highlight">independent chefs and super-serve communities</span>.
          </p>
        </div>

        <div className="about-card">
          <p>
            Mr. Patterson's journey is to uplift <span className="highlight">independent culinary artists and shops</span>. The Flying Pot is about building relationships with chefs and collaborating with them to help their food reach the people who will love it. Mr. Patterson's goal is to use <span className="highlight">technology and art to elevate</span> his partners and The Flying Pot business to new heights, blending his culinary heritage with his technological expertise to make a lasting <span className="highlight">impact</span> on the food industry.
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
