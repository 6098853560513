import React, { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorsDisplay from './ErrorsDisplay';
import UserContext from '../context/UserContext';
import ThemeContext from '../context/ThemeContext';
import '../styles/GuestStyles.css'; //Ensure this path is correct
import { api } from '../utils/apiHelper';
//trip
const GuestSignIn = () => {
  const { actions } = useContext(UserContext);
  const { accentColor } = React.useContext(ThemeContext);
  const navigate = useNavigate();

  const emailRef = useRef(null);

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationVisible, setVerificationVisible] = useState(false);
  const [generatedCode, setGeneratedCode] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [generatedUsername, setGeneratedUsername] = useState('');
  const [generatedPassword, setGeneratedPassword] = useState('');
  const [generatedName, setGeneratedName] = useState('');

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const checkUserExists = async (email) => {
    try {
      const response = await api("/check-user", "POST", { email });
      return response.errors || null;
    } catch (error) {
      setErrors([error.message]);
      return null;
    }
  };

  const generateRandomString = (length) => {
    return Math.random().toString(36).substring(2, 2 + length);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const name = `guest-${generateRandomString(6)}`;
    const username = `guest-${generateRandomString(6)}`;
    const password = `guest-${generateRandomString(8)}`;

    const user = {
      email,
      name,
      username,
      password,
      kind: "guest"
    };

    // Client-side validation
    let validationErrors = [];
    if (!user.email) {
      validationErrors.push('Email is required.');
    }
    if (!isValidEmail(user.email)) {
      validationErrors.push('Invalid email format.');
    }

    setErrors(validationErrors);

    if (validationErrors.length === 0) {
      const userExistsErrors = await checkUserExists(user.email);
      if (userExistsErrors) {
        setErrors(Object.values(userExistsErrors));
        return;
      }

      try {
        const code = Math.floor(100000 + Math.random() * 900000).toString();
        setGeneratedCode(code);
        setGeneratedName(name);
        setGeneratedUsername(username);
        setGeneratedPassword(password);
        const response = await api('/send-verification-code', 'POST', { email: user.email, code });
        if (response.message === 'Verification email sent.') {
          alert('A verification email has been sent to your email address.');
          setUserEmail(user.email);
          setVerificationVisible(true);
        } else {
          setErrors(['Error sending verification email. Please try again.']);
        }
      } catch (error) {
        console.error('Error sending verification email:', error);
        setErrors(['Error sending verification email. Please try again.']);
      }
    }
  };

  const handleVerification = async (event) => {
    event.preventDefault();

    if (verificationCode !== generatedCode) {
      setErrors(['Invalid verification code.']);
      return;
    }

    try {
      const user = {
        name: generatedName,
        username: generatedUsername,
        email: userEmail,
        password: generatedPassword,
        kind: "guest"
      };
      const response = await api('/users/add', 'POST', user);
      console.log('User created response:', response);
      if (response.errors) {
        setErrors(response.errors);
        return;
      }
      const signInResponse = await actions.signIn({ email: user.email, password: user.password });
      if (signInResponse) {
        navigate("/guest-authenticated");
      } else {
        setErrors(['Sign-in failed. Please try again.']);
      }
    } catch (error) {
      console.error('Error creating user or signing in:', error);
      setErrors(['Error creating user or signing in. Please try again.']);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    navigate("/");
  };

  return (
    <div className="guest-signin-wrapper">
      <div className="guest-signin-card">
        <h1>Guest Sign In</h1>
        <ErrorsDisplay errors={errors} />
        <div>
          {!isVerificationVisible ? (
            <form onSubmit={handleSubmit}>
              <input
                id="email"
                name="email"
                type="email"
                ref={emailRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <div className="pad-bottom">
                <button className="button" type="submit" style={{ background: accentColor }}>Sign In as Guest</button>
                <button className="button button-secondary" style={{ color: accentColor }} onClick={handleCancel}>Cancel</button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleVerification}>
              <input
                id="verificationCode"
                name="verificationCode"
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Enter Verification Code"
              />
              <button className="button" type="submit" style={{ background: accentColor }}>Verify</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuestSignIn;
