import React, { useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { api } from '../utils/apiHelper';
import ErrorsDisplay from './ErrorsDisplay';
import UserContext from '../context/UserContext';
import ThemeContext from '../context/ThemeContext';
import '../styles/UserSignUp.css'; // Ensure this path is correct

const UserSignUp = () => {
  const { actions } = React.useContext(UserContext);
  const { accentColor } = React.useContext(ThemeContext);
  const navigate = useNavigate();

  const nameRef = useRef(null);
  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordARef = useRef(null);

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordA, setPasswordA] = useState('');
  const [kind] = useState('user');
  const [errors, setErrors] = useState([]);
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationVisible, setVerificationVisible] = useState(false);
  const [generatedCode, setGeneratedCode] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [canResend, setCanResend] = useState(true); // Controls resend button state
  const [resendTimeout, setResendTimeout] = useState(0); // Timeout duration

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const checkUserExists = async (email, username) => {
    try {
      const response = await api("/check-user", "POST", { email, username });
      return response.errors || null;
    } catch (error) {
      setErrors([error.message]);
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const user = {
      name,
      username,
      email,
      password,
      passwordA,
      kind: kind.length > 4 ? "admin" : "user",
    };

    let validationErrors = [];
    if (!user.name || !user.username || !user.email || !user.password || !user.passwordA) {
      validationErrors.push('All fields are required.');
    }
    if (user.password !== user.passwordA) {
      validationErrors.push('Passwords do not match.');
    }
    if (!isValidEmail(user.email)) {
      validationErrors.push('Invalid email format.');
    }

    setErrors(validationErrors);

    if (validationErrors.length === 0) {
      const userExistsErrors = await checkUserExists(user.email, user.username);
      if (userExistsErrors) {
        setErrors(Object.values(userExistsErrors));
        return;
      }

      sendVerificationCode(user.email);
    }
  };

  const sendVerificationCode = async (email) => {
    try {
      const code = Math.floor(100000 + Math.random() * 900000).toString();
      setGeneratedCode(code);
      const response = await api('/send-verification-code', 'POST', { email, code });
      if (response.message === 'Verification email sent.') {
        setUserEmail(email);
        setVerificationVisible(true);
        setCanResend(false); // Disable resend initially
        setResendTimeout(30); // Set resend timeout (30 seconds)

        // Enable resend after the timeout
        const timer = setInterval(() => {
          setResendTimeout((prev) => {
            if (prev === 1) {
              clearInterval(timer);
              setCanResend(true);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      } else {
        setErrors(['Error sending verification email. Please try again.']);
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      setErrors(['Error sending verification email. Please try again.']);
    }
  };

  const handleResendCode = () => {
    if (canResend) {
      sendVerificationCode(userEmail);
    }
  };

  const handleVerification = async (event) => {
    event.preventDefault();

    if (verificationCode !== generatedCode) {
      setErrors(['Invalid verification code.']);
      return;
    }

    try {
      const user = {
        name,
        username,
        email: userEmail,
        password,
        kind: kind.length > 4 ? "admin" : "user",
      };
      const response = await api('/users/add', 'POST', user);
      console.log('User created response:', response);
      const signInResponse = await actions.signIn({ email: user.email, password: user.password });
      if (signInResponse) {
        navigate("/authenticated");
      } else {
        setErrors(['Sign-in failed. Please try again.']);
      }
    } catch (error) {
      console.error('Error creating user or signing in:', error);
      setErrors(['Error creating user or signing in. Please try again.']);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    navigate("/");
  };

  return (
    <div className="bounds">
      <div className="grid-33 centered signin card">
        <h1>Sign up</h1>
        <div>
          <ErrorsDisplay errors={errors} />

          {!isVerificationVisible ? (
            <form onSubmit={handleSubmit}>
              <input
                id="name"
                name="name"
                type="text"
                ref={nameRef}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
              <input
                id="username"
                name="username"
                type="text"
                ref={usernameRef}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="User Name"
              />
              <input
                id="email"
                name="email"
                type="email"
                ref={emailRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <input
                id="password"
                name="password"
                type="password"
                ref={passwordRef}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <input
                id="passwordA"
                name="passwordA"
                type="password"
                ref={passwordARef}
                value={passwordA}
                onChange={(e) => setPasswordA(e.target.value)}
                placeholder="Re-type Password"
              />
              <div className="pad-bottom">
                <button className="button" type="submit" style={{ background: accentColor }}>Register</button>
                <button className="button button-secondary" style={{ color: accentColor }} onClick={handleCancel}>Cancel</button>
              </div>
            </form>
          ) : (
            <div>
              {/* Message about verification code sent */}
              <p>A verification code has been sent to your email. Please enter the code below to complete your registration.</p>
              
              <form onSubmit={handleVerification}>
                <input
                  id="verificationCode"
                  name="verificationCode"
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter Verification Code"
                />
                <button className="button" type="submit" style={{ background: accentColor }}>Verify</button>
              </form>
              
              {/* Resend Code Section */}
              <p>Didn't receive the code or it expired?</p>
              <button
                className="button"
                onClick={handleResendCode}
                disabled={!canResend}
                style={{ background: canResend ? accentColor : 'gray' }}
              >
                {canResend ? 'Resend Code' : `Resend available in ${resendTimeout}s`}
              </button>
            </div>
          )}
        </div>
        <p>
          Already have a user account? <Link style={{ color: accentColor }} to="/signin">Click here</Link> to sign in!
        </p>
      </div>
    </div>
  );
};

export default UserSignUp;
//heroku update
