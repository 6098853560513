import React, { useEffect, useState, useContext } from 'react';
import UserContext from '../context/UserContext';
import { api } from '../utils/apiHelper';
import '../styles/SoldEvents.css'; // Assuming you'll create a CSS file for custom styling

const SoldEvents = () => {
  const { authUser } = useContext(UserContext);
  const [eventOrders, setEventOrders] = useState([]);
  const [users, setUsers] = useState({}); // Store users for purchasedBy lookups
  const [error, setError] = useState(null);
  const [checkedTickets, setCheckedTickets] = useState({});
  const [expandedEventId, setExpandedEventId] = useState(null); // Track which event is expanded

  useEffect(() => {
    const fetchEventOrders = async () => {
      try {
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/eventOrders`);
        if (response && Array.isArray(response)) {
          setEventOrders(response || []);
          
          // Fetch user information based on `purchasedBy` IDs
          const userIds = response.map(order => order.purchasedBy);
          const uniqueUserIds = [...new Set(userIds)]; // Avoid fetching duplicate users

          const usersResponse = await Promise.all(
            uniqueUserIds.map(userId => api(`/users/${userId}`))
          );

          const usersMap = usersResponse.reduce((map, user) => {
            map[user._id] = user; // Store user info by _id for lookup
            return map;
          }, {});

          setUsers(usersMap); // Store user data for lookups
        } else {
          setError('Failed to fetch event orders.');
        }
      } catch (error) {
        console.error('Error fetching event orders or user information:', error);
        setError('Failed to fetch event orders.');
      }
    };

    if (authUser && authUser._id) {
      fetchEventOrders();
    }
  }, [authUser]);

  const handleCheckboxChange = async (ticketId, eventId) => {
    const newCheckedState = !checkedTickets[ticketId];

    setCheckedTickets((prevCheckedTickets) => ({
      ...prevCheckedTickets,
      [ticketId]: newCheckedState,
    }));

    if (newCheckedState) {
      try {
        await api(`/users/${encodeURIComponent(authUser._id)}/patronEvents/${eventId}/tickets/${ticketId}`, 'PUT', {
          claimed: true,
        });
      } catch (error) {
        console.error('Error updating ticket status:', error);
        setError('Failed to update ticket status.');
      }
    }
  };

  const toggleExpand = (eventId) => {
    setExpandedEventId(expandedEventId === eventId ? null : eventId);
  };

  // Helper function to format time in 12-hour format
  const formatTime = (time) => {
    const date = new Date(`1970-01-01T${time}Z`);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  return (
    <div className="sold-events-container">
      <h2>Your Event Orders</h2>
      {error && <p className="error">{error}</p>}
      {eventOrders.length > 0 ? (
        <ul className="event-list">
          {eventOrders.map(order => (
            <li key={order._id} className="event-item">
              <div className="event-header" onClick={() => toggleExpand(order._id)}>
                <h3>{order.eventName}</h3>
                <p>Purchased by: {users[order.purchasedBy]?.name || 'Unknown User'}</p>
                <p>Date: {new Date(order.date).toLocaleString()}</p>
                <p>Seating Time: {formatTime(order.seating)}</p>
                <p>Total Tickets: {order.tickets?.length || 0}</p>
                <button className="expand-btn">{expandedEventId === order._id ? '-' : '+'}</button>
              </div>
              {expandedEventId === order._id && (
                <div className="event-details">
                  <p>Seating Key: {order.seatingKey}</p>
                  <h4>Tickets:</h4>
                  <ul className="ticket-list">
                    {order.tickets.map((ticket, index) => (
                      <li key={index} className="ticket-item">
                        <label>
                          <input
                            type="checkbox"
                            checked={!!checkedTickets[ticket.ticketId]}
                            onChange={() => handleCheckboxChange(ticket.ticketId, order._id)}
                            style={{ marginRight: '8px' }}
                          />
                          Ticket ID: {ticket.ticketId} {ticket.claimed ? '(Claimed)' : '(Not Claimed)'}
                        </label>
                      </li>
                    ))}
                  </ul>
                  <p className="total-price">Total Price: ${(order.tickets.length * order.price).toFixed(2)}</p>
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p>No event orders found.</p>
      )}
    </div>
  );
};

export default SoldEvents;
