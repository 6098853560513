import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext';
import '../styles/kitchenCompliments.css';
import { api } from '../utils/apiHelper';

const KitchenCompliments = () => {
  const { authUser } = useContext(UserContext);
  const [kitchenCompliments, setKitchenCompliments] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [clickedToDelete, setClickedToDelete] = useState(null); // Track first click for deletion
  const navigate = useNavigate();

  useEffect(() => {
    const fetchKitchenCompliments = async () => {
      try {
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/compliments/kitchen`);
        console.log('Fetched kitchen compliments:', response.compliments); // Debugging log
        setKitchenCompliments(response.compliments || []);
      } catch (error) {
        console.error('Error fetching kitchen compliments:', error);
        setErrorMessage('An error occurred while fetching compliments. Please try again later.');
      }
    };

    fetchKitchenCompliments();
  }, [authUser._id]);

  const formatToPacificTime = (dateString, timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date(dateString);
    date.setHours(hours, minutes);

    const options = {
      timeZone: 'America/Los_Angeles',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };

    return date.toLocaleString('en-US', options);
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleUserDelete = async (complimentId) => {
    if (clickedToDelete === complimentId) {
      // Second click: Proceed with deletion
      console.log('Deleting compliment with ID:', complimentId);
      try {
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/compliments/${encodeURIComponent(complimentId)}`, 'DELETE');
        if (response.success) {
          console.log('Compliment deleted successfully.');
          setKitchenCompliments(prevCompliments => prevCompliments.filter(compliment => compliment.id !== complimentId));
        } else {
          console.error('Failed to delete the compliment:', response);
          setErrorMessage('Failed to delete the compliment.');
        }
      } catch (error) {
        console.error('Error deleting compliment:', error);
        setErrorMessage('An error occurred while deleting the compliment. Please try again later.');
      }
      setClickedToDelete(null); // Reset after deletion
    } else {
      // First click: Set up for confirmation
      console.log('First click: Setting up delete confirmation for compliment with ID:', complimentId);
      setClickedToDelete(complimentId);
      setErrorMessage('Click again to confirm deletion.');
    }
  };

  const handleStoreDelete = async (complimentId) => {
    console.log('Deleting compliment due to nonexistent store with ID:', complimentId);
    try {
      const response = await api(`/users/${encodeURIComponent(authUser._id)}/compliments/${encodeURIComponent(complimentId)}`, 'DELETE');
      if (response.success) {
        console.log('Compliment deleted successfully.');
        setKitchenCompliments(prevCompliments => prevCompliments.filter(compliment => compliment.id !== complimentId));
      } else {
        console.error('Failed to delete the compliment:', response);
        setErrorMessage('Failed to delete the compliment.');
      }
    } catch (error) {
      console.error('Error deleting compliment:', error);
      setErrorMessage('An error occurred while deleting the compliment. Please try again later.');
    }
  };

  const handleStoreClick = async (storeId, complimentId) => {
    console.log('Checking store with ID:', storeId);
    try {
      const response = await api(`/stores/exist/${encodeURIComponent(storeId)}`);

      if (response.success) {
        console.log('Store exists, navigating to store page.');
        navigate(`/stores/${storeId}`);
      } else {
        console.log('Store does not exist, deleting compliment.');
        handleStoreDelete(complimentId);
        setErrorMessage('Store not found. Compliment removed.');
      }
    } catch (error) {
      console.error('Error checking store existence:', error);
      setErrorMessage('An error occurred while checking the store. Please try again later.');
    }
  };

  const renderCompliment = (compliment, index) => (
    <div key={compliment.id} className="promotion-section">
      <div onClick={() => toggleExpand(index)} style={{ cursor: 'pointer', marginBottom: '10px' }}>
        <p onClick={() => handleStoreClick(compliment.storeId, compliment.id)} className="store-link">
          <strong>Store Name:</strong> {compliment.storeName}
        </p>
        <p><strong>Title:</strong> {compliment.title}</p>
        <p>
          <strong>Amount:</strong> {compliment.amount}% <span className="small-text">(off order)</span>
        </p>
        <p><strong>End Time:</strong> {formatToPacificTime(compliment.startDate, compliment.endTime)}</p>
        <button onClick={() => handleUserDelete(compliment.id)} className="delete-button">
          <img src="remove.png" alt="Delete" style={{ width: '16px', height: '16px' }} /> {/* Placeholder for your delete icon */}
        </button>
      </div>
      {expandedIndex === index && (
        <div>
          <p><strong>Group ID:</strong> {compliment.groupId}</p>
          <p><strong>Start Date:</strong> {compliment.startDate}</p>
          <hr />
        </div>
      )}
      <hr className="promotion-divider" />
    </div>
  );

  return (
    <div className="kitchen-compliment-container">
      <div className="kitchen-compliment-card no-background">
        <h2 style={{ textAlign: 'center' }}>Promotions</h2>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <div className="kitchen-compliments-list">
          {kitchenCompliments && kitchenCompliments.length > 0 ? (
            kitchenCompliments.map((compliment, index) => (
              renderCompliment(compliment, index)
            ))
          ) : (
            <p>Keep an eye out for promotions!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default KitchenCompliments;
