import React from 'react';
import '../styles/BusinessPage.css'; // Assuming you will add CSS for styling

const BusinessPage = () => {
  return (
    <div className="business-page-container">
      <div className="card">
        <h2>Launching Kitchens to New Heights</h2>
        <p><strong>Super Serve Your Audience</strong></p>
        <p>Connect with the fans of your food and strengthen your brand identity.</p>
        <p>Create and host unique events to expand your store’s brand and reach.</p>
      </div>

      <div className="card">
        <h1><strong>Run Promotion Campaigns</strong></h1>
        <p>Easily create and distribute promotions for your store. Gain insights into your customers’ buying habits and preferences.</p>
      </div>

      <div className="card">
        <h1><strong>Event Creation</strong></h1>
        <p>Host unique events with ease. Use our pop-out feature to create and manage events for your store. Sell tickets to special dinners, parties, and gatherings.</p>
      </div>

      <div className="card">
        <h1>Order Management</h1>
        <p>Manage the communication, payment, and delivery process of your orders seamlessly.</p>
      </div>

      <div className="card">
        <h1>Simple and Secure Payments</h1>
        <p>Ensure secure card payments and enjoy hassle-free payouts.</p>
      </div>
    </div>
  );
};

export default BusinessPage;
