import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import UserContext from '../context/UserContext';
import '../styles/StripePay.css';
import { api } from '../utils/apiHelper';

const StripePay = ({ total, tip }) => {
  const { authUser } = useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [name, setName] = useState('');
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const response = await api(`/users/${encodeURIComponent(authUser._id)}/cart`);
        setCart(response.cart);
      } catch (error) {
        console.error('Error fetching cart data:', error);
        setError('Failed to fetch cart data.');
      }
    };

    const createPaymentIntent = async () => {
      try {
        const amountInCents = Math.round(total * 100);
        const response = await api('/create-payment-intent', 'POST', { amount: amountInCents });
        setClientSecret(response.clientSecret);
      } catch (error) {
        console.error('Error creating payment intent:', error);
        setError('Failed to create payment intent.');
      }
    };

    if (total > 0) {
      fetchCartData();
      createPaymentIntent();
    }
  }, [total, authUser]);

  const generateRandomString = (length = 8) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const generateTickets = (quantity) => {
    const tickets = [];
    for (let i = 0; i < quantity; i++) {
      tickets.push({
        ticketId: `ticket_${generateRandomString()}`,
        claimed: false,
      });
    }
    return tickets;
  };

  const generateRandomOrderNumber = () => {
    return Math.floor(Math.random() * 500) + 1;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const confirmed = window.confirm("Do you want to process this payment?");
    if (!confirmed) return;
  
    setProcessing(true);
  
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: name,
        },
      },
    });
  
    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setSucceeded(true);
      setProcessing(false);
  
      const mainkey = generateRandomString();
      const timestamp = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
      const orderNumber = generateRandomOrderNumber();
  
      const groupedCartItems = cart.reduce((acc, item) => {
        const storeOwnerId = item.storeId.split('-')[0];
        if (!acc[storeOwnerId]) {
          acc[storeOwnerId] = [];
        }
        acc[storeOwnerId].push(item);
        return acc;
      }, {});
  
      for (const [storeOwnerId, items] of Object.entries(groupedCartItems)) {
        const eventItems = items.filter(item => item.eventId);
        const regularItems = items.filter(item => !item.eventId);
  
        if (eventItems.length > 0) {
          for (const eventItem of eventItems) {
            const eventPurchase = {
              _id: eventItem._id,
              eventName: eventItem.eventName,
              description: eventItem.description,
              date: eventItem.date,
              seating: eventItem.seating,
              quantity: eventItem.quantity,
              price: eventItem.price,
              eventId: eventItem.eventId,
              ccname: name,  // Include the cardholder's name
              seatingKey: eventItem.seatingKey,
              storeId: eventItem.storeId,
              tickets: generateTickets(eventItem.quantity),
              mainkey,
              timestamp,
              cartTotal: eventItem.price * eventItem.quantity,
              orderNumber,
              purchasedBy: authUser._id,
              tip: parseFloat(tip || 0),
            };
  
            try {
              // Post to the store owner's event orders
              await api(`/users/${storeOwnerId}/eventOrders`, 'POST', eventPurchase);
  
              // Post to PatronEvents collection
              await api(`/users/${encodeURIComponent(authUser._id)}/patronEvents`, 'POST', eventPurchase);
  
            } catch (error) {
              console.error('Error processing event order:', error);
            }
          }
        }
  
        if (regularItems.length > 0) {
          const newOrder = {
            items: regularItems,
            mainkey,
            timestamp,
            ccname: name,  // Include the cardholder's name
            cartTotal: regularItems.reduce((total, item) => total + item.price * item.quantity, 0),
            orderNumber,
            PatronId: authUser._id,
            tip: parseFloat(tip || 0),
          };
  
          try {
            await api(`/users/${storeOwnerId}/orders`, 'POST', newOrder);
            await api(`/users/${encodeURIComponent(authUser._id)}/patronOrders`, 'POST', {
              ...newOrder,
              storeName: regularItems[0].storeName,
            });
  
            await api('/send-purchase-email', 'POST', {
              email: authUser.email,
              storeName: regularItems[0].storeName,
              ccName: name,
              cartTotal: newOrder.cartTotal,
              items: newOrder.items.map(item => ({
                itemName: item.itemName,
                price: item.price,
                quantity: item.quantity,
              })),
              timestamp,
            });
          } catch (error) {
            console.error('Error processing item order:', error);
          }
        }
      }
  
      try {
        await api(`/users/${encodeURIComponent(authUser._id)}/cart/clear`, 'DELETE');
      } catch (error) {
        console.error('Error clearing cart:', error);
      }
  
      navigate('/authenticated');
      setName('');
      elements.getElement(CardElement).clear();
    }
  };
  
  

  return (
    <form id="stripe-pay-form" onSubmit={handleSubmit} className="stripe-pay-container">
      <div>
        <label>
          Name
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="stripe-pay-form-input"
          />
        </label>
      </div>
      <CardElement className="CardElement" />
      <button disabled={processing || succeeded} className="stripe-pay-form-button">
        {processing ? 'Processing...' : 'Pay'}
      </button>
      {error && <div className="error-message">{error}</div>}
      {succeeded && <div className="success-message">Payment succeeded!</div>}
    </form>
  );
};

export default StripePay;
