import { Link } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../context/UserContext";
import "../styles/Nav.css"; // Ensure this import is correct

const Nav = ({ menuOpen, onClose }) => {
  const { authUser } = useContext(UserContext);

  const handleLinkClick = () => {
    onClose && onClose();
  };

  return (
    <nav className={`nav ${menuOpen ? "open" : "hidden"}`}>
      {authUser === null ? (
        <>
          <Link
            className="nav-link roboto-bold signup"
            to="/signup"
            onClick={handleLinkClick}
          >
            Sign up
          </Link>
          <Link
            className="nav-link roboto-bold signin"
            to="/signin"
            onClick={handleLinkClick}
          >
            Sign in
          </Link>
        </>
      ) : (
        <>
          <span className="nav-welcome">Welcome {authUser.name}</span>
          <Link
            className="nav-link roboto-bold home"
            to={authUser.kind === "guest" ? "/guest_Auth" : "/authenticated"}
            onClick={handleLinkClick}
          >
            Home
          </Link>
          {!authUser.kind || authUser.kind !== "guest" ? (
            <>
              <Link
                className="nav-link roboto-bold createstore"
                to="/createstore"
                onClick={handleLinkClick}
              >
                Store
              </Link>
              <Link
                className="nav-link roboto-bold updatestore"
                to="/updatestore"
                onClick={handleLinkClick}
              >
                Manage
              </Link>
              <Link
                className="nav-link roboto-bold settings"
                to="/settings"
                onClick={handleLinkClick}
              >
                Settings
              </Link>
            </>
          ) : null}
          <Link
            className="nav-link roboto-bold storelist"
            to="/storelist"
            onClick={handleLinkClick}
          >
            Store List
          </Link>
          <Link
            className="nav-link roboto-bold paymentprocess"
            to="/paymentprocess"
            onClick={handleLinkClick}
          >
            Cart
          </Link>
          <Link
            className="nav-link roboto-bold followers"
            style={{ display: "none" }}
            to="/followers"
            onClick={handleLinkClick}
          >
            Followers
          </Link>
          <Link
            className="nav-link roboto-bold user"
            style={{ display: "none" }}
            to="/userlist"
            onClick={handleLinkClick}
          >
            Users
          </Link>
          <Link
            className="nav-link roboto-bold signout"
            to="/signout"
            onClick={handleLinkClick}
          >
            Sign Out
          </Link>
        </>
      )}
    </nav>
  );
};

export default Nav;
